<template>
  <div class="relative isolate px-0 lg:px-6 sm:px-4 -z-1">

    <div class="min-h-[calc(100vh-120px)] flex flex-col items-center justify-center">

      <div class="isolate relative max-w-full bg-indigo-50 shadow-xl opacity-90 rounded-xl sm:rounded-3xl py-16 md:py-16 px-4 lg:px-10 sm:px-5 overflow-hidden">

        <svg viewBox="0 0 1024 1024"
          class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          aria-hidden="true">
          <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.6" />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stop-color="indigo" />
            </radialGradient>
          </defs>
        </svg>
        <div class="mx-auto  max-w-full text-center lg:flex-auto lg:py-16 ">
          <h1 class="text-balance text-3xl font-bold tracking-tight text-gray-700 sm:text-4xl lg:text-6xl">AI-Powered Solutions For
            Research Publishing Workflows</h1>
          <p class="mt-6 text-sm leading-8 text-gray-700">We build algorithms and tools that help publishers, reviewers,
            and editors accomplish more in less time</p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a href="#getstarted"
              class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get
              started</a>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="getstarted" class="overflow-hidden bg-white pt-10 sm:pt-10">

    <div class="mx-auto max-w-7xl px-6 lg:px-8">

      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:pr-8 lg:pt-4">
          <div class="lg:max-w-lg">
            <h2 class="text-base font-semibold leading-7 text-indigo-500 mt-2">Automate Peer Review Workflows</h2>
            <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Find the Right Experts, Instantly.
            </p>
            <p class="mt-6 text-lg leading-8 text-gray-600">
              Unlock the power of AI-driven recommendation engine with access to over 50M experts and their research.
            </p>

            <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
              <div class="relative pl-9">
                <dt class="inline font-semibold text-gray-900">
                  <BeakerIcon class="size-6 text-blue-500" /> Quickly match expert reviewers to content based on
                  research summaries, abstracts, and free-form queries
                </dt>
                <dd class="inline"></dd>
              </div>

              <!-- <div class="relative pl-9">
                <dt class="inline font-semibold text-gray-900">
                  <SparklesIcon class="size-6 text-blue-500" /> Generate initial peer review reports using custom AI models
                </dt>
                <dd class="inline"></dd>
              </div> -->

              <div class="relative pl-9">
                <dt class="inline font-semibold text-gray-900">
                  <UserGroupIcon class="size-6 text-blue-500" /> Ensure peer review integrity with automated conflict of
                  interest checks
                </dt>
                <dd class="inline"></dd>
              </div>

              <div class="relative pl-9">
                <dt class="inline font-semibold text-gray-900">
                  <ChatBubbleBottomCenterIcon class="size-6 text-blue-500" />
                  Enhance relevance by discovering related research
                </dt>
                <dd class="inline"></dd>
              </div>

              <div class="relative pl-9">
                <dt class="inline font-semibold text-gray-900">
                  <CpuChipIcon class="size-6 text-blue-500" /> Seamless integration directly into your editorial
                  systems via our APIs
                </dt>
                <dd class="inline"></dd>
              </div>


            </dl>
          </div>
        </div>
        <img :src="require('@/assets/cover.png')" alt="Product screenshot"
          class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
          width="2432" height="1442">
      </div>
    </div>




    <div class="flex flex-col items-center justify-center m-4 font-light text-gray-900  rounded-md p-5">

      <GlobeAmericasIcon class="size-12 text-indigo-500" />
      <span class="m-4">Get in touch to schedule a demo</span>
      <button @click="showForm = !showForm" class="bg-indigo-500 text-white rounded hover:bg-indigo-400 p-2">Contact
        us</button>
      <div v-if="showForm" class="transition-transform transform ease-out duration-500 m-4 w-full"
        :class="{ 'translate-y-0': showForm, '-translate-y-full': !showForm }">
        <ContactForm />
      </div>
    </div>
    <div v-if="!user" class="flex flex-col items-center justify-center m-4 font-light text-gray-900 p-5 rounded-md">
      <CheckBadgeIcon class="size-12 text-indigo-500" />
      <span class="m-4">Log In For a Trial</span>
      <LoginForm v-if="!user" />
    </div>





    










  </div>

  <div class="flex flex-col items-center justify-center  ">
      <div class="rounded-3xl isolate relative max-w-full bg-indigo-50 shadow-xl opacity-80 p-4 lg:px-16 sm:px-5 overflow-hidden">
        <svg viewBox="0 0 1024 1024"
          class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          aria-hidden="true">
          <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.1" />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stop-color="indigo" />
            </radialGradient>
          </defs>
        </svg>
        <div class="mx-auto max-w-7xl">
          <div class="mx-auto max-w-2xl lg:text-center">
            <p class="text-2xl font-bold tracking-tight text-gray-900">Whom we serve</p>
          </div>
          <div class="mx-auto max-w-2xl mt-5 lg:max-w-4xl">
            <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              <div
                class="relative pl-8 rounded-lg bg-indigo-50 hover:bg-indigo-200  transition-colors duration-1000 ease-in-out p-6 sm:p-1">
                <BookOpenIcon class="size-12 text-indigo-500" />
                <dt class="text-2xl font-semibold leading-7 text-gray-900 m-4">
                  Research publishers
                </dt>
                <dd class="m-4 sm:m-1 text-base leading-7 text-gray-600">
                  Searching for expert reviewers is a major bottleneck in your editorial workflows.
                  With our reviewer recommender solution, you can identify qualified experts based on manuscript text,
                  without manual input and without conflict-of-interest checks.
                </dd>
                <hr class="m-4 border-t-2 border-indigo-300" />
                <dd class="text-center">
                  Contact us to book a demo.
                </dd>
              </div>
              <div
                class="relative pl-8 rounded-lg bg-indigo-50 relative pl-8 rounded-lg bg-indigo-50 hover:bg-indigo-200  transition-colors duration-1000 ease-in-out p-6">
                <UsersIcon class="size-12 text-indigo-500" />
                <dt class="text-2xl font-semibold leading-7 text-gray-900 m-4">
                  Editorial boards
                </dt>
                <dd class="m-4 text-base leading-7 text-gray-600">
                  It is becoming harder and harder to secure a full reviewer panel for your papers.
                  Query the recommender using article abstracts or narrow down experts to a specific area of expertise with custom queries.
                  Get reviewer contact details instantly. 
                </dd>
                <hr class="m-4 border-t-2 border-indigo-300" />
                <dd class="text-center">
                  Get started with a free individual trial.
                </dd>

              </div>


            </dl>
          </div>
        </div>
      </div>
    </div>

  <div class="flex flex-col items-center justify-center m-1 font-light text-gray-900 rounded-md p-5">
    <!-- Service Agreement Link -->
    <a href="#" @click.prevent="togglePopup" class="text-indigo-500 underline hover:text-indigo-400 text-sm">
      Privacy Policy, Cookies, and User Agreement
    </a>

    <!-- Popup Window -->
    <div v-if="showPopup" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white rounded-lg max-w-5xl shadow-lg p-6 relative overflow-auto max-h-[80vh]">
        <!-- Close Button -->
        <button @click="togglePopup"
          class="p-2 text-white hover:text-white-900 bg-indigo-500 hover:bg-indigo-400  rounded">
          Close
        </button>

        <!-- Service Agreement Content -->
        <div class="transition-transform transform ease-out duration-500 m-1 w-full">
          <ServiceAgreement />
        </div>
        <button @click="togglePopup" class="p-2 text-white bg-indigo-500 hover:bg-indigo-400  rounded">
          Close
        </button>
      </div>
    </div>
  </div>




</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */

import LoginForm from './LoginForm.vue';
import ContactForm from './ContactForm.vue';
import { BeakerIcon, ChatBubbleBottomCenterIcon, UserGroupIcon, CpuChipIcon, CheckBadgeIcon, GlobeAmericasIcon, SparklesIcon } from '@heroicons/vue/24/outline'
import { BookOpenIcon, UsersIcon } from '@heroicons/vue/24/solid'

import ServiceAgreement from './ServiceAgreement.vue';

export default {
  props: ['user'],
  data() {
    return {
      showForm: false, // To track form visibility
      showPopup: false,
    };
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },

  },
  components: {
    LoginForm,
    ContactForm,
    BeakerIcon,
    ChatBubbleBottomCenterIcon,
    UserGroupIcon,
    CpuChipIcon,
    CheckBadgeIcon,
    GlobeAmericasIcon,
    SparklesIcon,
    BookOpenIcon,
    UsersIcon,
    ServiceAgreement,
  }
};
</script>


<style scoped>
/* Optional: Additional styles for the popup window */
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent background */
}

.bg-white {
  background-color: white;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top-2 {
  top: 0.5rem;
}

.right-2 {
  right: 0.5rem;
}
</style>